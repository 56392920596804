<template>
  <v-card elevation="3" style="width: 100%; min-height: 500px;">

    <v-row no-gutters class="mx-2">

      <!-- Button -->
      <v-col cols="12">
        <v-row justify="end" no-gutters>
        </v-row>
      </v-col>

      <!-- Info -->
      <v-col cols="12">

        <v-row no-gutters>

          <!-- Contact details -->
          <v-col cols="12" class="my-1">
            <span>{{ $t('suppliers.labels.contactFormEmail') }} : </span>
            <span>{{ supplier.details.contactEmail }}</span>
          </v-col>

          <!-- Persons -->
          <v-col cols="12">
            <h3>{{ $t('suppliers.tabs.contacts.persons.title') }}</h3>

            <ContactDisplay
                :items="supplier.contacts"
                :base_url_api_add="`/suppliers/${supplier.id}/contacts`"
                :base_url_api_edit="`/suppliers/${supplier.id}/contacts`"
                :base_url_api_delete="`suppliers/${supplier.id}/contacts`"
                @refresh="$emit('refresh')"
            />


          <!-- Secondary Addresses --
          <v-col cols="12">
            <h3>{{ $t('suppliers.tabs.contacts.addresses.title')}}</h3>
            <v-row no-gutters>

              <v-col cols="12" md="4" v-if="account.main_address">
                <Address
                  :address="account.main_address"
                />
              </v-col>
              <template v-if="account.secondary_addresses.length > 0">

              <v-col v-for="address of account.secondary_addresses" cols="12" md="4" :key="address.id">
                <Address
                  :address="address"
                />
              </v-col>
              </template>
              <p v-else> {{ $t('suppliers.tabs.contacts.addresses.no-data') }}</p>
            </v-row>
          </v-col-->
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

export default {
  name: "SupplierContactsTab",

  components: {
    ContactDisplay: () => import("@/components/Common/Cards/ContactDisplay"),
    // Skeleton: () => import("@/components/Common/Cards/Skeleton"),
    // Person: () => import("@/components/Common/Cards/Person")
  },
  mixins: [],

  props: {
    supplier: {type: Object, default: () => null}
  },

  data() {
    return {
    }
  },

  methods: {
    getData() {
      this.$emit('refresh');
    },

    addContact(val) {
      this.$http
          .post(`/suppliers/${this.supplier.id}/contacts`, val, {
            headers: { Authorization: "Bearer " + this.$session.get('jwt') }
          })
          .then(res => {
            this.$emit('refresh');
          })
          .catch(err => {
            this.$store.commit("toast/showErrorAxios", err);
          })
          .finally(() => {
          })
    },

    editContact(val) {

    },

    deleteContact(id) {

    }
  }
}
</script>

<style scoped>

</style>
